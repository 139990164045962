.apply-job-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2%;
}

.apply-job-container form {
    width: 70%;
    margin: 20px;
}

.apply-job-container label {
    font-size: 1rem;
    margin: 10px 0px;
    display: block;
    color: black;
}

.apply-job-container input, .apply-job-container select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    color: black;
}
.apply-job-container option{
    color: black;
}
.add-skill-addJob{
    padding: 5px 8px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
}

.apply-job-container .btn {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width:45%;
    font-size: 1.2rem;
}

.btn:disabled {
    background-color: #ccc; /* Grey color when disabled */
    cursor: not-allowed;
  }

.apply-job-container .btn:not([disabled]):hover {
    background-color: #45a049;
}

.apply-job-container .input-group {
    display: flex;
    justify-content: space-between;
}

.job-page-title{
    color:black;
    text-align:center;
    margin-bottom:10px;
    font-size: 30px;
    font-weight: 800;
}

.apply-job-container .input-group > div {
    width: 48%; /* Adjust as needed */
}

.error-message{
    color: red
}

@media (max-width: 768px) {
    .apply-job-container form {
        width: 80%;
    }
    
    .apply-job-container input {
        width: 100%;
    }
    
    .apply-job-container .input-group {
        flex-direction: column;
    }
    .apply-job-container .input-group > div {
        width: 90%; /* Adjust as needed */
    }
}
