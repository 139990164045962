/* Softer Green Theme Button with Bold Text & Thick Border */
.animated-button {
  position: relative;
  display: inline-block;
  padding: 12px 24px;
  border: 3px solid #147a38; /* Slightly Lighter Dark Green */
  font-size: 16px;
  background-color: inherit;
  border-radius: 100px;
  font-weight: 700; /* Bold Text */
  color: #1c7e42; /* Medium Green */
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button span:last-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: #0e5e2a; /* Slightly Lighter Green */
  border-radius: 50%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button span:first-child {
  position: relative;
  z-index: 1;
}

.animated-button:hover {
  background-color: #0e5e2a; /* Softer Dark Green */
  color: #ffffff; /* White Text */
  border-color: #0e5e2a; /* Softer Dark Green Border */
  box-shadow: 0 0 0 5px #1c7e4280; /* Softer Green Glow */
}

.animated-button:active {
  scale: 0.95;
}

.animated-button:hover span:last-child {
  width: 150px;
  height: 150px;
  opacity: 1;
}
