.footer {
  color: #ffffff;
  padding: 30px 10px 10px 20px;
  font-family: 'Afacad';
  letter-spacing: 1px;
  background-color: #333;
  background-size: cover;
  font-family: 'Afacad';
  width: 100%;

}

.logo img{
  max-width: none;
  width: 200px;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  margin: 0 auto;
  gap: 20px;
}

.footer-left {
  flex: 1;
  max-width: 400px;
}

.footer-logo {
  margin-bottom: 5px;
  
}

.footer-text {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 10px;
  font-family: 'Afacad';

}

.social-icons-footer {
  display: flex;
  gap: 10px;
  margin-top: 30px;
}

.social-icons-footer img {
  width: 100%;
  height: 20px;
  transition: transform 0.2s ease;
}

.social-icons-footer img:hover {
  transform: scale(1.1);
}

.footer-middle {
  flex: 1;
  max-width: 200px;
}

.footer-middle h4 {
  color: #FDFA01;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.footer-middle ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-middle ul li{
  padding-bottom: 30px;
}



.footer-middle ul li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Afacad';
  
}

.footer-middle,.courses ul li a:hover {
  color: #FDFA01;

}

.footer-right {
  display: flex;
  gap: 20px;
}





.courses {
  max-width: 300px;
}


.courses h4 {
  color: #FDFA01;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}


.courses ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.courses ul li {
  font-size: 14px;
  margin-bottom: 11px;
  font-weight: bold;
  text-decoration: none;
  color: #ffffff;

}


.courses ul li a{
  font-size: 20px;
  margin-bottom: 8px;
  font-weight: bold;
  text-decoration: none;
  color: #ffffff;

}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-left,
  .footer-middle,
  .footer-right, .footer-end {
    max-width: 100%;
  }

  .footer-right {
    flex-direction: column;
  }
    .social-icons-footer {
      justify-content: center; 
      margin: 10px 0; 
    }
}



@media (min-width: 1300px) {
 
  .footer {
    color: #ffffff;
    padding: 30px 10px 10px 20px;
    font-family: 'Afacad';
    letter-spacing: 1px;
    font-family: 'Afacad';

  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    margin: 0 auto;
    gap: 20px;
    padding: 20px;
  }
  
  .footer-left {
    flex: 1;
    max-width: 400px;
    align-self: flex-start;
  }
  
  .footer-logo {
   width: 200px;
    margin-bottom: 5px;
    height: auto;
  }
  
  .footer-text {
    font-size: 1.3rem;
    line-height: 1.6;
    margin-bottom: 10px;
    font-family: 'Afacad';
  
  }
  
  .social-icons-footer {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .social-icons-footer img {
    width: 20px;
    height: 20px;
    transition: transform 0.2s ease;
  }
  
  .social-icons-footer img:hover {
    transform: scale(1.1);
  }
  
  .footer-middle {
    flex: 1;
    max-width: 300px;
  }
  
  .footer-middle h4 {
    color: #FDFA01;
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: bold;
    font-family: 'Afacad';
  }
  
  .footer-middle ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-middle ul li {
    margin-bottom: 10px;
  }
  
  .footer-middle ul li a {
    text-decoration: none;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Afacad';
  }
  
  .footer-middle ul li a:hover {
    color: #FDFA01;
  }
  
  .footer-right {
    display: flex;
    gap: 20px;
  }
  
  .branches strong{
    position: relative;
    padding-left: 15px;
    color: #FDFA01;
  }
  
  
  
  

  .courses {
    max-width: 300px;
  }
  
  .courses h4 {
    color: #FDFA01;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: 'Afacad';
    font-weight: bold;

  }
 
  .courses ul {
    list-style: none;
    padding: 0;
    margin: 0;

  }
  
  .courses ul li {
    font-size: 16px;
    margin-bottom: 11px;
    font-weight: bold;
    font-family: 'Afacad';

  }
}

.footer-branches {
  margin-top: 20px;
  text-align: center;
  border: 2px solid #FDFA01; /* Yellow border for the entire section */
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
  margin-bottom: 50px;
  margin-left: 20px;
  margin-right: 20px;
}

.footer-branches span {
  display: inline-block;
  color: #000000;
  font-size: 1.5rem;
  background-color: #FDFA01;
  text-align: center;
  font-weight: 700;
  padding: 5px 15px; /* More padding for better visual balance */
  border-radius: 5px;
  margin-bottom: 15px;
}

.branches-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin-top: 20px;
}

.branches-list div {
  flex: 1;
  min-width: 200px;
}

.branches-list strong {
  color: #FDFA01;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px;
}

.play-icon{
  font-size: 40px;
  margin-right: 0px;
  margin: 0px;
  padding: 0px;
}

.branches-list p {
  font-size: 1rem;
  line-height: 1.6;
  color: #ffffff; /* Text color for better contrast */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .branches-list {
    flex-direction: column;
    align-items: center;
  }
}
