
.bde-head {
  color: #000000;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 50px;
  font-weight: bold;
}

.bde-dashboard {
  font-family: 'Afacad';
  background-color: #e1e7ff;
    display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  min-height: 100vh;
}

.bde-loading,
.bde-error {
  text-align: center;
  font-size: 18px;
  color: #777;
}

.job-grid {
 display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
 
  gap: 20px;
  max-width: 80%;
}

.job-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  padding: 25px;
  transition: all 0.3s ease;
}

.job-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.1);
}


.job-card-header {
  margin-bottom: 20px;
}

.job-card-title {
  font-size: 1.3rem;
  font-weight: bold;
  color: rgb(204, 51, 102);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-card-company {
  font-size: 0.8rem;
  color: #101010;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.job-card-details {
  font-size: 14px;
}

.job-card-info {
  font-size: 1rem;
  color: #343131;
  margin: 5px 0;
  font-weight: 500;
}

.job-card-info.span {
  color: black;
  font-weight: bold;
}

.job-card-description {
  color: #495057;
}

.job-card-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.view-button {
  background-color: #37429B;
  border: none;
  margin-top: 10px;
  font-size: 12px;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  width: 50px;
  color: #ffffff;
}

.job-card-tag {
  display: inline-block;
  background-color: #e6f4f1;
  color: #00796b;
  font-size: 0.7rem;
  padding: 5px 10px;
  margin: 5px 5px 0 0;
  border-radius: 5px;
  font-weight: 700;
}

.job-modal-overlay {
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 30px;
}

.job-modal-bde {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 700px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease;
  overflow-y: auto;
  max-height: 80vh;
  bottom: 50px;
  overflow-y: auto;
  max-height: 70vh;
}


.job-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.job-modal-close:hover {
  color: #f60a0a;
}

.job-modal-title {
  color: #cc3366;
  font-weight: 600;
  font-size: 25px;
}

.job-modal-info {
  color: black;
  font-weight: 500;
  margin-bottom: 6px;
}

.job-modal-tag {
  display: inline-block;
  background-color: #e6f4f1;
  color: #00796b;
  font-size: 0.7rem;
  padding: 5px 10px;
  margin: 5px 5px 0 0;
  border-radius: 5px;
  font-weight: 700;
}

.job-modal-special-note {
  margin-top: 20px;
  padding: 8px;
  border-left: 4px solid #ecc440;
  border-radius: 5px;
  font-weight: 600;
}

.job-modal-special-note h3 {
  margin: 0 0 10px;
  color: #333;
}

.job-modal-special-note p {
  margin: 0;
  color: #555;
  font-size: 0.85rem;
}

/* form details */

.job-edit-form {
  width: 60%;
  margin: 0 auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width:1500px){
  
} 

@media screen and (max-width:650px) {
  .job-edit-form {
    width: 90%;
  }
}

.form-title {
  text-align: center;
  font-size: 22px;
  color: black;
}

.job-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  font-weight: bold;
  color: black;
}

.form-input,
.form-textarea {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  color: black;
}

.select {
  width: 75% !important;
}



.form-textarea {
  resize: vertical;
  height: 100px;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
}

.submit-btn,
.cancel-btn {
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.submit-btn {
  background-color: #4CAF50;
  color: white;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
}


.btns {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.applied-students-list {

  text-decoration: none;
  background-color: #0ca2d9;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
}

.edit-btn,
.applied-students-list {
  padding: 8px 12px;
  border-radius: 4px;
  text-decoration: none;
  color: white;
  cursor: pointer;
  border: none;
  font-weight: bold;
}

.edit-btn {
  background-color: rgb(246, 197, 90) !important;
  color: black !important;
}


.applied-students-list {
  background-color: #0ca2d9;
}

.skills-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-skill-btn,
.add-year-btn {
  padding: 5px 8px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
  padding-top: 11px;
  padding-bottom: 11px;
  width: 100px;
}


.selected-skills {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skill-option {
  color: black;
  cursor: pointer;
}

.selected-skill {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  padding: 5px 10px;
  border-radius: 3px;
  color: black;
}

.remove-skill-btn {
  margin-left: 5px;
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}


.years-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.selected-years {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.selected-year {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  padding: 5px 10px;
  border-radius: 3px;
  color: black;
}

.remove-year-btn {
  margin-left: 5px;
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.other {
  color: black;
}