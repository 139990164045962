.studentapplied-main{
    min-height: 80vh;
}
.students-applied-job-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}

.students-applied-job-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.students-applied-job-card h3 {
    margin-bottom: 10px;
    color: #c36; /* Red color for names */
    font-family: "Angkor", serif;
    font-weight: 400;
    font-style: normal;
}

.students-applied-job-card .students-applied-job-key {
    color: #363f8f; /* Blue color for values */
    font-family: "Angkor", serif;
    font-weight: 400;
    font-style: normal;
}

.students-applied-job-card span {
    margin: 5px 0;
    color: black;
}