/* Style for the Student Dashboard Header */
.student-head {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    margin-top: 10px;
    align-items: center;
}

/* Wrapper for job list with a flex layout */
.job-list-wrapper {
    display: flex;
    justify-content: flex-start; /* Align items to the left to accommodate the sidebar */
    align-items: center;
    background-color: #e1e7ff;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
    min-height: 100vh;
}

/* Adjustments for loading text */
.loading {
    color: #000;
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
}

/* Job list container grid */
.job-list-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));  
    gap: 19px;
    padding: 20px;
    margin: 0;
    flex-wrap: wrap;
    max-width: 80%;

}

/* Media query for smaller screens */
@media screen and (max-width: 576px) {
    .job-list-container {
        max-width: 90%; /* Take more width on smaller screens */
    }
}

/* Styling for job cards */
.job-card {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    padding: 20px;
}

/* Hover effect for job cards */
.job-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Job card header (title, company name) */
.job-header {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.job-title {
    font-size: 1.3rem;
    font-weight: bold;
    color: #cc3366;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0.5px;
}

.company-name {
    font-size: 1rem;
    color: #1f1c1c;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Job info section (CTC, location, etc.) */
.job-info .sub-info {
    font-size: 1rem;
    color: #110f0f;
    margin: 5px 0;
    font-weight: 500;
}

.strong {
    font-weight: bold;
    color: #000;
    font-size: 1rem;
}

.job-description {
    font-size: 0.8rem;
    color: #777;
    margin-top: 10px;
}

/* Tags for technologies used in job */
.tags {
    margin-top: 10px;
}

.tag {
    display: inline-block;
    background-color: #e6f4f1;
    color: #00796b;
    font-size: 0.9rem;
    padding: 5px 10px;
    margin: 5px 5px 0 0;
    border-radius: 5px;
    font-weight: 700;
}

/* Footer for job card (buttons, etc.) */
.job-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.posted-date {
    font-size: 0.8rem;
    color: #999;
}

/* If the job is closed */
.closed-job {
    background-color: #f8f8f8;
}

/* Apply button styles */
.apply-btn {
    background-color: #071ff8;
    color: #fff;
    border: none;
    padding: 10px 15px;
    font-size: 0.6rem;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.apply-btn:hover {
    background-color: #2a306b;
}

/* Button when job is closed or already applied */
.closed-btn {
    background-color: #ccc;
    color: black;
    font-size: 0.8rem;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: not-allowed;
}

/* Modal styles */
.job-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 30px;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 700px;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease;
    overflow-y: auto;
    max-height: 70vh;
}

/* Mobile view for the modal */
@media screen and (max-width: 676px) {
    .modal-content {
        margin: 20px;
    }

    .job-modal {
        height: 100%;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Close modal button */
.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
}

.close-modal:hover {
    color: #ff0000;
}

/* Special Note section in the modal */
.special-note {
    margin-top: 20px;
    padding: 8px;
    border-left: 4px solid #ecc440;
    border-radius: 5px;
    font-weight: 600;
}

.special-note h3 {
    margin: 0 0 10px;
    color: #333;
}

.special-note p {
    margin: 0;
    color: #555;
    font-size: 0.85rem;
}

/* Error message when fetching jobs */
.error-message {
    color: red;
    font-size: 1rem;
    text-align: center;
}

/* Job role in modal */
.job-role {
    color: #cc3366;
    font-weight: 600;
    font-size: 30px;
}

.strong {
    color: #000;
}

/* Item in the job modal */
.pop-up-item {
    color: black;
    font-weight: 500;
    margin-bottom: 6px;
}

/* Button inside job modal */
.apply-job-list-btn {
    background-color: green;
    cursor: pointer;
    margin-top: auto;
    align-self: flex-start;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: #ffffff;
}

/* Applied button style */
.apply-job-list-btn.applied {
    background-color: #ecc440;
    color: black;
    font-weight: 700;
    cursor: not-allowed;
}
