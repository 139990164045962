

/* Query Section */
.query-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
  margin-top: 80px;
}

.callback-button-query {
  background-color: #FDFA01; /* Bright yellow */
  color: #000;
  padding: 5px 10px;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Afacad';
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  letter-spacing: 1px;
}

.callback-button-query:hover {
  background-color: #FDFA01;
  transform: scale(1.05);
}

.query-background {
  position: relative;
  background-color: #f0f0f0; 
    background-size: cover;
  background-position: center;
  border-radius: 12px;
  padding: 2rem;
  color: #ffffff;
  display: flex;
  max-width: 100%;
  margin-top: 120px;
  min-height: 299px;
  margin-left: 30px;
  margin-right: 30px;
  padding-left: 90px;
  border-radius: 30px;

}

/* Content */
.query-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.query-content h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-family: 'Afacad';
  letter-spacing: 1px;
  align-self: flex-start;

  
}

.query-content p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  font-family: 'Afacad';
  font-weight: 550;
  letter-spacing: 1px;
  align-self: flex-start;
  max-width: 80%;

}


.student-img {
  position: absolute;
  top: -71%; /* Part of the image extends outside the top */
  right: -2%; /* Part of the image extends outside the right */
  z-index: 3; /* Ensure it appears above the card */

}

@media screen and (min-width:1500px){
  .query-content{
    max-width: 80%;
    padding-bottom: 30px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .query-content h1{
    font-size: 3rem;
    letter-spacing: 2px;
    font-weight: 600;
    color: #ffffff;
  }
  .query-content p{
    font-size: 1.8rem;
    letter-spacing: 2px;
    font-weight: 500;
    max-width:85%;
    padding-bottom: 50px;
  }
  .query-background{
    height: 450px;
  }
  .query-section{
    min-height: 80vh;
  }
  
.student-img {
  position: absolute;
  top: -55%; 
  right: -2%; 
  z-index: 3; 
  height: 700px;
  width: 30%;
}

.callback-button-query {
  background-color: #FDFA01; /* Bright yellow */
  color: #000;
  padding: 0.75rem 1rem;
  font-size: 2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Afacad';
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  letter-spacing: 1px;
}

  .call {
      width: 45px;
      height: 45px;
      margin-right: 15px;
  }
  


}

@media screen and  (min-width:1200px) and (max-width:1500px){
  .query-content h1{
    font-size: 2.5rem;
     max-width:100%
  }
  .query-content p{
    font-size: 1.1rem;
    font-weight: 500;
  }
  .query-content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  

}

@media screen and (min-width:976px) and (max-width:1200px){
  .student-img{
    display: none;
  }
  .query-content{
    min-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .query-content p{
    min-width: 100%;
    margin-bottom: 20px;
    font-weight: 500;
    text-align: center;

  }
  .query-background{
    padding: 50px;
  }
  .query-background{
    min-height: 400px;
    margin-top: 0px;
  }
  .query-section{
    margin-top: 0px;
  }
  .query-content h1{
    margin-bottom: 20px;
    align-self: center;
    text-align: center;

  }
}

@media screen and (min-width:576px) and (max-width:976px){
  .student-img{
    display: none;
  }
  .query-content{
    min-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .query-content p{
    min-width: 100%;
    margin-bottom: 20px;
    font-weight: 500;
    align-self: center;
  }
  .query-background{
    padding: 50px;
  }
  .query-background{
    min-height: 400px;
    margin-top: 0px;
  }
  .query-section{
    margin-top: 0px;
  }
  .query-content h1{
    margin-bottom: 20px;
    align-self:center ;
  }
}

@media screen and (max-width:576px){
.query-content p{
  font-size: 1.3rem;
  text-align: center;
  align-self: center;
  font-weight: 400;
}
.query-content h1{
  font-size: 2rem;
  text-align: center;
  margin-bottom: 30px;
  align-self: center;
  font-weight: 600;
}
.query-background{
  margin-top: 0px;
  border-radius: 10px;
  margin: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 30px;
}
.query-section{
  margin-top: 0px;
}
.callback-button-query {
  background-color: #FDFA01; /* Bright yellow */
  color: #000;
 padding: 5px;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Afacad';
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
 
}
.student-img{
  display: none;
}
.query-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

}