
.testimonials-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px 70px;
  background-color: white;
  position: relative;
}

.title {
  font-size: 2.4rem;
  margin-bottom: 50px;
  font-weight: 600;
  color: #000000;
  font-family: 'Afacad';
  letter-spacing: 2px;
}

.testimonial-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  z-index: 2;

}

.circle-images {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: rotate 10s linear infinite;
  transform-origin: center;
  cursor: pointer;
}

.circle-img {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 2px solid #e0e7ff;
  object-fit: cover;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.gradient {
  position: absolute;
  border-radius: 15px;
  z-index: -1;
}

.gradient.top-right {
  width: 140px;
  height: 120px;
  top: -40px;
  right: -40px;
  background: linear-gradient(to right, #FFFFFF 0%, #f40428 100%);
}

.gradient.bottom-left {
  width: 140px;
  height: 120px;
  bottom: -40px;
  left: -40px;
  background: linear-gradient(270deg, #FFFFFF 0%, #0C22B3 100%);
}

.testimonial-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px #00000040;
  max-width: 550px;
  margin: 50px auto;
}

.circle-img.top-left {
  top: -1px;
  left: -100px;
  transform: translateY(-30%);
}

.circle-img.middle-left {
  top: 40%;
  left: -160px;
  transform: translateY(-30%);
  width: 80px;
  height: 80px;
}

.circle-img.bottom-left {
  bottom: 2%;
  left: -150px;
  transform: translateY(-30%);
}

.circle-img.top-right {
  top: -10px;
  right: -140px;
  width: 80px;
  height: 80px;
  transform: translateY(-30%);
}

.circle-img.middle-right {
  top: 40%;
  right: -180px;
  transform: translateY(-30%);
  width: 70px;
  height: 70px;
}

.circle-img.bottom-right {
  top: 70%;
  right: -150px;
  width: 85px;
  height: 85px;
  transform: translateY(-30%);

}

.card-content-wrapper {
  display: flex;
  position: relative;
  z-index: 2;
  justify-content: center;
  align-items: center;

}

.card-image img {
 margin: 30px;
  border-radius: 5px;
  object-fit: cover;
  border: 2px solid #FFFFFF;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  margin-left: 20px;
}

.card-content {
  text-align: left;
  max-width: 400px;
  margin-left: 2px;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2px;
  color: #020031;
  font-family: 'Afacad';
  font-weight: 600;
  letter-spacing: 8px;
}

.card-rating {
  font-size: 1.5rem;
  color: #ffb400;
  margin-bottom: 5px;
  letter-spacing: 5px;
}

.card-description {
  font-size: 0.9rem;
  color: #020031;
  font-family: 'Afacad';
  font-weight: 400;
}

.smile-icon {
  align-self: flex-end;
  width: 50px;
  height: 50px;
}

 .smile-icon {
  min-width: 48px; /* Ensure sufficient clickable width */
  min-height: 48px; /* Ensure sufficient clickable height */
  padding: 8px; /* Add padding around the icons */
  display: inline-flex; /* Maintain alignment */
  justify-content: center; 
  align-items: center;
}

.circle-img.highlighted {
  transform: scale(1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.circle-img.clicked {
  border: 3px solid #ff6347;
  transform: scale(1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.circle-images.paused {
  animation-play-state: paused;
}




@media screen and (min-width: 1400px) {


  .title {
    font-size: 3rem;
    margin-bottom: 150px;

  }

  .testimonial-card {
    max-width: 900px;
    padding: 10px;
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.25), 0 10px 15px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
  }

  .card-image img {
    max-width: 250px;
    height: 350px;
    margin-right: 15px;
  }

  .card-title {
    font-size: 1.8rem;
    letter-spacing: 8px;
  }

  .card-description {
    font-size: 1.2rem;
  }

  .circle-img {
    width: 150px;
    height: 150px;
  }

  .circle-img.top-left {
    top: -9%;
    left: -25%;
    width: 110px;
    height: 110px;
  }

  .circle-img.middle-left {
    left: -45%;
    width: 150px;
    height: 150px;
  }

  .circle-img.bottom-left {
    left: -33%;
    bottom: -15%;
    width: 110px;
    height: 110px;
  }

  .circle-img.top-right {
    top: -9%;
    right: -30%;
    width: 150px;
    height: 150px;
  }

  .circle-img.middle-right {
    right: -45%;
    width: 130px;
    height: 130px;
  }

  .circle-img.bottom-right {
    right: -33%;
    top: 90%;
    width: 120px;
    height: 120px;
  }

  .gradient.top-right {
    width: 180px;
    height: 160px;
    top: -60px;
    right: -40px;
  }

  .gradient.bottom-left {
    width: 180px;
    height: 160px;
    bottom: -60px;
    left: -40px;
  }

  .card-rating{
    font-size: 2rem;
    letter-spacing: 10px;
  }
  
}

@media screen and (min-width:1080px) and   (max-width:1450px){
 

  .title {
    font-size: 2rem;
    letter-spacing: 2px;
    margin-bottom: 50px;

  }

  .testimonial-card {
    max-width: 600px;
    padding: 10px;
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.25), 0 10px 15px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
  }

  .card-image img {
    min-width: 150px;
    height: 200px;
  }

  .card-title {
    font-size: 1rem;
    letter-spacing: 4px;
  }

  .card-description {
    font-size: 15px;
    font-weight: 400;
  }

  .circle-img {
    width: 90px;
    height: 90px;
  }
  .card-content{
    margin-left: 30px;
  }

  .circle-img.top-left {
    top: -9%;
    left: -20%;
    width: 100px;
    height: 100px;
  }

  .circle-img.middle-left {
    left: -37%;
    width: 110px;
    height: 110px;
  }

  .circle-img.bottom-left {
    left: -30%;
    bottom: -15%;
    width: 90px;
    height: 90px;
  }

  .circle-img.top-right {
    top: -15%;
    right: -30%;
    width: 80px;
    height: 80px;
  }

  .circle-img.middle-right {
    right: -39%;
    width: 110px;
    height: 110px;
  }

  .circle-img.bottom-right {
    right: -30%;
    top: 90%;
    width: 100px;
    height: 100px;
  }

  .gradient.top-right {
    width: 180px;
    height: 100px;
    top: -50px;
    right: -50px;
  }

  .gradient.bottom-left {
    width: 180px;
    height: 100px;
    bottom: -50px;
    left: -50px;
  }
  .card-rating{
    font-size: 1rem;
  }
  .smile-icon{
    width: 30px;
    height: 30px;
  }
}

@media screen and (min-width: 576px) and (max-width:1080px) {
  .gradient.top-right {
    width: 100px;
    height: 100px;
    top: -10px;
    right: -10px;
  }
  .testimonials-section{
    margin-top: 30px;
  }
  .testimonial-container{
    padding: 0px;
  }

  .testimonial-card{
    margin: 0px;
    padding: 20px;
  }


  .card-rating{
    letter-spacing: 2px;
    font-size: 1.5rem;
  }
  .card-title{
    font-size: 2rem;
  }

  .gradient.bottom-left {
    width: 100px;
    height: 100px;
    bottom: -30px;
    left: -10px;
    background: linear-gradient(270deg, #FFFFFF 0%, #0C22B3 100%);
  }

  .card-description {
    font-size: 1.5rem;
  }
  .card-content-wrapper{
    flex-direction: column;
    width: 100%;
  }

  .card-image img {
    width: 100%;
    border-radius: 10%;
    object-fit: cover;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin: 0px;
  }

  .testimonial-card {
   margin: 20px;
   align-self: center;
  }

  .gradient.top-right {
    width: 100px;
    height: 100px;
    top: -40px;
    right: -30px;
    background: linear-gradient(to right, #FFFFFF 0%, #f40428 100%);
  }

  .gradient.bottom-left {
    width: 100px;
    height: 100px;
    bottom: -50px;
    left: -30px;
    background: linear-gradient(270deg, #FFFFFF 0%, #0C22B3 100%);
  }

  .circle-img{
    display: none;
  }

 

  .title {
    font-size: 2.5rem;
    text-align: center;
  }
  .smile-icon{
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 576px) {
  .gradient.top-right {
    width: 100px;
    height: 100px;
    top: -10px;
    right: -10px;
  }
  .testimonials-section{
    margin-top: 20px;
  }
  .testimonial-container{
    padding: 0px;
  }

  

  .card-rating{
    letter-spacing: 4px;
    font-size: 20px;
  }
  .card-title{
    font-size: 1.1rem;
    letter-spacing: 3px;
    margin-top: 10px;
  }

  .gradient.bottom-left {
    width: 100px;
    height: 100px;
    bottom: -30px;
    left: -10px;
    background: linear-gradient(270deg, #FFFFFF 0%, #0C22B3 100%);
  }

  .card-description {
    font-size: 18px;
  }
  .card-content-wrapper{
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }

  .card-image img {
    border-radius: 10%;
    object-fit: cover;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin: 0px;
    width: 70%;
    height: auto;

  }
  .card-image{
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .testimonial-card {
   margin: 20px;
  }

  .gradient.top-right {
    width: 60px;
    height: 90px;
    top: -40px;
    right: -20px;
    background: linear-gradient(to right, #FFFFFF 0%, #f40428 100%);
  }

  .gradient.bottom-left {
    width: 60px;
    height: 90px;
    bottom: -40px;
    left: -20px;
    background: linear-gradient(270deg, #FFFFFF 0%, #0C22B3 100%);
  }

  .circle-img{
    display: none;
  }

  .title {
    font-size: 1.6rem;
    text-align: center;
  }
  .smile-icon{
    width: 30px;
    height: 30px;
  }
}

